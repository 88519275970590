export default {
  items: [
    {
      name: 'Home',
      url: '/dashboard',
      icon: 'icon-home',
    },
    {
      name: 'Trust Questions',
      url: '/scorecard',
      icon: 'icon-menu',

    },
    {
      name: 'Trust Models',
      icon: 'icon-share',

    },
    {
      name: 'Trust Tools',
      url: '/tools',
      icon: 'icon-wrench',

    },
    {
      name: 'Reports',
      url: 'http://docs.trustindex.ca/reports/Machine%20Trust%20Reports.pdf',
      icon: 'icon-chart',
    }
  ],
};
