import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import jwt        from 'jsonwebtoken';
import _          from 'lodash';

const jwksClient = require('jwks-rsa');

const auth_domain     = 'https://auth.trustindex.ca';
const cognito_pool_id = 'ca-central-1_S1d0sjEeI'
const client_id       = 't1fvb9d3l0gc5pf4n36m9i7jv';
const redirect_uri    = window.location.protocol + '//' + window.location.hostname;
const scope           = 'email+openid+profile+aws.cognito.signin.user.admin';
const response_type   = 'code';

class CognitoLogin extends Component {

  constructor(props) {
    super(props);

    this.jwksClient = jwksClient({
      strictSsl: false,
      cache: true,
      rateLimit: true,
      jwksUri: `https://cognito-idp.ca-central-1.amazonaws.com/${cognito_pool_id}/.well-known/jwks.json`
    });

    this.getValidTokens()
        .then(() => ReactDOM.render(<App />, document.getElementById('root')))
        .catch(() => {
          localStorage.removeItem('id_token');
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          this.startUserAuth();
        });
  }

  getValidTokens() {
    if (_.isNil(localStorage.id_token) || _.isNil(localStorage.access_token) || _.isNil(localStorage.refresh_token)) {
      const params = new URLSearchParams(window.location.search);
      if (params.has('code')) {
        return this.exchangeCodeForJWTs(params.get('code')).catch(error => this.startUserAuth());
      }
      this.startUserAuth();
      return Promise.reject({name: 'MissingTokens'});
    }

    return this.verifyJWT(localStorage.id_token)
               .then(() => this.verifyJWT(localStorage.access_token))
               .catch(error => {
                  if (error.name === 'TokenExpiredError') {
                    return this.refreshJWTs();
                  }
               });
  }

  verifyJWT(token) {
    return new Promise((resolve, reject) => {
      jwt.verify(token,
                 (header, callback) => this.jwksClient.getSigningKey(header.kid, (err, key) => callback(null, key.publicKey || key.rsaPublicKey)),
                 (error, decoded) => {
                    if (error) {
                      reject(error);
                    }
                    resolve(decoded);
                 });
    });
  }

  startUserAuth() {
      window.location = `${auth_domain}/login?` +
                        `client_id=${client_id}&` +
                        `redirect_uri=${redirect_uri}&` + 
                        `response_type=${response_type}&` +
                        `scope=${scope}`;
  }

  exchangeCodeForJWTs(code) {
    return fetch(`${auth_domain}/oauth2/token`, {
              method: 'POST',
              body: `grant_type=authorization_code&` +
                    `client_id=${client_id}&`        +
                    `code=${code}&`    +
                    `redirect_uri=${redirect_uri}`,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
           })
           .then(response =>  {
              if (!response.ok) { throw response.json(); }
              return response.json();
           })
           .then(tokens => {
                localStorage.setItem('access_token',  tokens.access_token);
                localStorage.setItem('id_token',      tokens.id_token);
                localStorage.setItem('refresh_token', tokens.refresh_token);
           })
           .then(() => {
              window.history.replaceState({}, '', '/');
           });
  }

  refreshJWTs() {
    if (_.isNil(localStorage.refresh_token)) {
      return Promise.reject('No refresh token found');
    }

    return fetch(`${auth_domain}/oauth2/token`, {
              method: 'POST',
              body: `grant_type=refresh_token&` +
                    `client_id=${client_id}&`   +
                    `refresh_token=${localStorage.refresh_token}`,
              headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
              }
          })
          .then(response =>  {
            if (!response.ok) { throw response.json() }
            return response.json();
          })
          .then(tokens => {
            localStorage.setItem('access_token',  tokens.access_token);
            localStorage.setItem('id_token',      tokens.id_token);
          });
  }

  render() {
    return <div></div>
  }
}

export default CognitoLogin;
