import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';


function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const Scorecard = Loadable({
  loader: () => import('./views/Scorecard'),
  loading: Loading,
});

const TrustModules = Loadable({
  loader: () => import('./views/TrustModules'),
  loading: Loading,
});

const Targets = Loadable({
  loader: () => import('./views/Targets'),
  loading: Loading,
});

const Reports = Loadable({
  loader: () => import('./views/Reports'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/scorecard', name: 'Scorecard', component: Scorecard },
  { path: '/trustModules', name: 'TrustModules', component: TrustModules },
  { path: '/tools', name: 'Targets', component: Targets },
  { path: '/reports', name: 'Reports', component: Reports }
];

export default routes;
