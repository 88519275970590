import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Badge, DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown, ButtonGroup, Nav, NavItem, NavLink} from 'reactstrap';

import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import Login from '../../CognitoLogin';
import logo from '../../assets/img/brand/logo.png'
import jwt  from 'jsonwebtoken';
import './headerLayout.css';


class DefaultHeader extends Component {

  constructor(props) {

    super(props);

    this.toggle        = this.toggle.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);

    const idToken = jwt.decode(localStorage.id_token);
    this.state =
    {
      dropdownOpen: false,
      givenName: idToken.given_name,
      familyName: idToken.family_name
    };
  }

  toggle()
  {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onClickLogout() {
    localStorage.clear();
    ReactDOM.render(<Login />, document.getElementById('root'));
  }

  getUserIconColor() {
    const colors = [
      "rgb(255, 193, 7)",   //yellow
      "rgb(248, 108, 107)", //red
      "rgb(77, 189, 116)",  //green
      "rgb(99, 194, 222",   //light blue
      "purple"
    ];

    const colorIndex = (this.state.givenName  && this.state.givenName.length > 0 ? this.state.givenName.charCodeAt(0) : 0 + 
                       this.state.familyName && this.state.familyName.length > 0 ? this.state.familyName.charCodeAt(0) : 0) %
                       colors.length;

    return colors[colorIndex];
  }

  getUserIconInitials() {
    let initials = "";
    initials += this.state.givenName  && this.state.givenName.length  > 0 ? this.state.givenName.charAt(0)  : "";
    initials += this.state.familyName && this.state.familyName.length > 0 ? this.state.familyName.charAt(0) : "";
    return initials.length > 0 ? initials.toUpperCase() : "X";
  }

  render() {

    return (
      <React.Fragment >
        <AppSidebarToggler className="d-lg-none" display="md" mobile style={{paddingRight: "10px"}}/>
        <AppNavbarBrand
          full={{ src: logo, width: 55, height: 55, alt: 'NuEnergy Logo' }}
        />

        <h2 style={{paddingLeft: "10px"}}> NuEnergy.ai&trade; Machine Trust Platform</h2>

        <Nav className="ml-auto" navbar>
          <NavItem className="d-md-down-none">
            <NavLink href="https://aia.trustindex.ca" target="_blank">AIA</NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-magnifier"></i></NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-bell"></i>
              <Badge pill color="danger">5</Badge>
            </NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-settings"></i></NavLink>
          </NavItem>
        </Nav>

        <ButtonGroup className="float-right h-100">
          <ButtonDropdown  className="h-100" isOpen={this.state.dropdownOpen} toggle={() => { this.toggle(); }}>
            <DropdownToggle caret className="rounded-0" color="dark" style={{ position:"relative", top:"-1px", fontFamily:": -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"" }}>
                <div style={{ display:"inline-block", height:"36px", width:"36px", borderRadius:"50%", backgroundColor:this.getUserIconColor(), color:"white", marginRight:"10px", lineHeight:"36px", fontSize:"18px"}}>
                  <span style={{ lineHeight:"32px" }}>{this.getUserIconInitials()}</span>
                </div>
                <span style={{ lineHeight:"32px" }}>{this.state.givenName} {this.state.familyName}</span>
            </DropdownToggle>
            <DropdownMenu right className="rounded-0">
              <DropdownItem> <a href="#">Profile</a> </DropdownItem>
              <DropdownItem> <a href="#" onClick={this.onClickLogout}>Sign out</a> </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </ButtonGroup>

      </React.Fragment>
    );
  }
}

export default DefaultHeader;
